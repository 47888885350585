/*  
    --------------------------------
    Screen Size - For Laptop 
    --------------------------------
*/

html {
    scroll-behavior: smooth;
}

@media only screen and (min-width: 1100px){
    .aboutMe-container{
        margin-top: 5%;
        padding-top: 10%; 
        text-align: center;
    }

    .aboutMe-title{
        color: white;
    }

    .aboutMe-desc{
        color: rgb(93, 92, 92);
        text-align: center;
        margin: 0 auto;
        text-align: justify;
        width: 50%;
        margin-bottom: 15%;
    }
}




/* 
    ---------------------------------
    Screen Size - For Medium Screens
    --------------------------------
*/


@media only screen and (min-width: 600px) and (max-width: 1100px){
    .aboutMe-container{
        margin-top: 10%;
        width: 100%;
        height: 900px;
        margin-bottom: 5%;
    }

    .aboutMe-title{
        color: white;
    }

    .aboutMe-desc{
        color: rgb(93, 92, 92);
        text-align: center;
        margin: 0 auto;
        text-align: justify;
        width: 75%;
        margin-bottom: 15%;
    }

}





/*  
    --------------------------------
    Screen Size - For Mobile 
    --------------------------------
*/

@media only screen and (max-width: 600px){
    .aboutMe-container{
        margin-top: 10%;
        width: 100%;
        height: 900px;
        margin-bottom: 5%;
    }

    .aboutMe-title{
        color: white;
    }

    .aboutMe-desc{
        color: rgb(93, 92, 92);
        text-align: center;
        margin: 0 auto;
        text-align: justify;
        width: 75%;
        margin-bottom: 15%;
    }
}