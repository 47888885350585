/*  
    --------------------------------
    Global Styles 
    --------------------------------
*/

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
    margin: 0;
    padding: 0;
}

.projects {
    padding: 2% 5%;
}

.projects-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: white;
    margin-top: 15%;
}

/*  
    --------------------------------
    Project Box Styles
    --------------------------------
*/

.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.projects-box {
    background-color: transparent;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    height: 300px; /* Adjusted height to accommodate images */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.projects-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-box-title {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 1.5rem;
    padding: 0.5rem;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.project-btn-container {
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: black;
}

.project-btn-Demo, .project-btn-Code, .project-btn-Info {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    padding: 1rem;
    text-align: center;
    transition: background-color 0.3s ease;
    flex: 1;
}

.project-btn-Demo:hover, .project-btn-Code:hover, .project-btn-Info:hover {
    background-color: #0056b3;
}

.project-btn-Info i {
    margin: 0;
    padding: 0;
}

.dropdown-content {
    padding-top: 50px;
    padding-bottom: 60px;
    font-size: 10px;
    color: #333;
    background-color: black;
    color: white;
}

/*  
    --------------------------------
    Image Background Styles
    --------------------------------
*/

#projects-portfolio-image {
    background-image: url('../images/OldPortfolio.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#projects-MovieInsider-image {
    background-image: url('../images/MovieInsider.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;    
}

#projects-RecipeWebsite-image {
    background-image: url('../images/RecipeWebsite.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;    
}

#projects-Workout-image {
    background-image: url('../images/workouttracker.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;    
}


#projects-newPortfolio-image {
    background-image: url('../images/NewPortfolio.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;   
}

/*  
    --------------------------------
    Responsive Styles
    --------------------------------
*/

/* Large screens */
@media (min-width: 1100px) {
    .projects-container {
        gap: 20px;
    }

    .projects-box {
        width: 300px;
    }
}

/* Medium screens */
@media (min-width: 600px) and (max-width: 1100px) {
    .projects {
        margin-top: 5%;
        padding-top: 10%;
    }    

    .projects-title {
        font-size: 2rem;
    }

    .projects-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }

    .projects-box {
        width: 45%;
        margin-bottom: 20px;
    }

    .project-btn-container {
        padding: 0.75rem;
    }
}

/* Small screens */
@media (max-width: 600px) {
    .projects {
        margin-top: 5%;
        padding-top: 10%;
    }

    .projects-title {
        font-size: 1.5rem;
    }

    .projects-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .projects-box {
        width: 100%;
        margin-bottom: 10px;
    }

    .project-btn-container {
        padding: 0.75rem;
    }
}