/*  
    --------------------------------
    Screen Size - For Laptop 
    --------------------------------
*/

@media only screen and (min-width: 1100px){
    .contactMe{
        margin-top: 27%;
        color: white;
        margin-bottom: 5%;
    }

    .name{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .company{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .email{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .message{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .submit-message{
        color: white;
    }

    .contactMe-form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .form-group {
        margin-bottom: 15px;
        width: 100%;
      }
      
      input, textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      .submit-message {
        width: 300px;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #28a745;
        color: white;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
      }
      
      .submit-message:hover {
        background-color: #218838;
      }
      
      .notification {
        margin-top: 15px;
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        width: 16%;
      }
      
      .notification.success {
        background-color: #d4edda;
        color: #155724;
        border: 1px solid #c3e6cb;
      }
      
      .notification.error {
        background-color: #f8d7da;
        color: #721c24;
        border: 1px solid #f5c6cb;
      }
    
}




/* 
    ---------------------------------
    Screen Size - For Medium Screens
    --------------------------------
*/

@media only screen and (min-width: 600px) and (max-width: 1100px){
    .contactMe{
        margin-top: 27%;
        color: white;
        margin-bottom: 5%;
    }

    .name{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .company{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .email{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .message{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .submit-message{
        color: white;
    }

    .contactMe-form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .form-group {
        margin-bottom: 15px;
        width: 100%;
      }
      
      input, textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      .submit-message {
        width: 300px;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #28a745;
        color: white;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
      }
      
      .submit-message:hover {
        background-color: #218838;
      }
      
      .notification {
        margin-top: 15px;
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        width: 16%;
      }
      
      .notification.success {
        background-color: #d4edda;
        color: #155724;
        border: 1px solid #c3e6cb;
      }
      
      .notification.error {
        background-color: #f8d7da;
        color: #721c24;
        border: 1px solid #f5c6cb;
      }
}





/*  
    --------------------------------
    Screen Size - For Mobile 
    --------------------------------
*/

@media only screen and (max-width: 600px){
    .contactMe{
        margin-top: 27%;
        color: white;
        margin-bottom: 5%;
    }

    .name{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .company{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .email{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .message{
        width: 300px;
        margin-bottom: 5px;
        color: white;
    }

    .submit-message{
        color: white;
    }

    .contactMe-form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .form-group {
        margin-bottom: 15px;
        width: 100%;
      }
      
      input, textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      .submit-message {
        width: 300px;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #28a745;
        color: white;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
      }
      
      .submit-message:hover {
        background-color: #218838;
      }
      
      .notification {
        margin-top: 15px;
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        width: 16%;
      }
      
      .notification.success {
        background-color: #d4edda;
        color: #155724;
        border: 1px solid #c3e6cb;
      }
      
      .notification.error {
        background-color: #f8d7da;
        color: #721c24;
        border: 1px solid #f5c6cb;
      }
}