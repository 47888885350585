/*  
    --------------------------------
    Screen Size - For Laptop 
    --------------------------------
*/

@media only screen and (min-width: 1100px){
    .skills-container{ 
        text-align: center;
    }

    .skills-title{
        color: white;
        background-color: transparent;
    }

    .skills-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1px;
        justify-content: center; 
        width: 800px; 
        margin: 0 auto; 
    }
    
    .skill {
        border: solid 1px rgb(73, 73, 73);
        color: rgb(73, 73, 73);
        padding: 5px;
        border-radius: 10px; 
        text-align: center;
        width: calc(50% - 3px); 
        margin-bottom: 5px; 
    }
    
    .skills-icon{
        width: 75x;
        height: 75px;
        margin: 7px;
    }
}




/* 
    ---------------------------------
    Screen Size - For Medium Screens
    --------------------------------
*/

@media only screen and (min-width: 600px) and (max-width: 1100px){
    .skills-container{ 
        text-align: center;
    }

    .skills-title{
        color: white;
        background-color: transparent;
    }

    .skills-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1px;
        justify-content: center; 
        width: 75%; 
        margin: 0 auto; 
    }
    
    .skill {
        border: solid 1px rgb(73, 73, 73);
        color: rgb(73, 73, 73);
        padding: 5px;
        border-radius: 10px; 
        text-align: center;
        width: calc(50% - 3px); 
        margin-bottom: 5px; 
    }
    
    .skills-icon{
        width: 75x;
        height: 75px;
        margin: 7px;
    }

}





/*  
    --------------------------------
    Screen Size - For Mobile 
    --------------------------------
*/

@media only screen and (max-width: 600px){
    .skills-container{ 
        text-align: center;
    }

    .skills-title{
        margin-top: 100px;
        color: white;
        background-color: transparent;
    }

    .skills-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1px;
        justify-content: center; 
        width: 75%; 
        margin: 0 auto; 
    }
    
    .skill {
        border: solid 1px rgb(73, 73, 73);
        color: rgb(73, 73, 73);
        padding: 5px;
        border-radius: 10px; 
        text-align: center;
        width: calc(50% - 3px); 
        margin-bottom: 5px; 
    }
    
    .skills-icon{
        width: 75x;
        height: 75px;
        margin: 7px;
    }

}
